import React, {useEffect} from "react"
import AOS from "aos";
import "aos/dist/aos.css";
import Footer from "../components/Footer"
import ImageAndText from "../components/ImageAndText"
import DoubleImage from "../components/DoubleImage"
import Hero from "../components/Hero"
import ValuePropositionTable from '../components/ValuePropositionTable'
import HouseBagniLogo from '../assets/logo/logo-bagni-white.svg'
import HouseTettiLogo from '../assets/logo/logo-tetti-white.svg'
import ContactForm from "../components/ContactForm";

const Home = (props) => {

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <div className="page home">
            <div className="container">
                <Hero 
                    img={'https://images.unsplash.com/photo-1541888946425-d81bb19240f5?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'}
                    imgAlt='alt'
                    title='BENVENUTO IN HOUSE!'
                    ctaText='SCOPRI CHI SIAMO'
                    ctaLink='#scroll'
                />
                <div id="scroll"></div>
                <DoubleImage 
                        img={'https://plus.unsplash.com/premium_photo-1676657955279-8fd22fbb75e0?q=80&w=2787&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'}
                        img2={'https://plus.unsplash.com/premium_photo-1676320514021-7c68dda90026?q=80&w=2787&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'}
                        imgAlt='alt'
                        imgAlt2='alt'
                        title='SICUREZZA SOPRA LA TUA TESTA!'
                        title2='NUOVO BAGNO, NUOVA VITA!'
                        ctaText='SCOPRI HOUSE TETTI'
                        ctaLink='tetti'
                        ctaText2='SCOPRI HOUSE BAGNI'
                        ctaLink2='bagni'
                        logo1={HouseTettiLogo}
                        logo2={HouseBagniLogo}
                    />
                <div className="inner-page">
                    <ImageAndText 
                        img={'https://images.unsplash.com/photo-1507652313519-d4e9174996dd?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'}
                        imgAlt='alt'
                        eyebrow='HOUSE BAGNI'
                        title='Rinnova il tuo benessere'
                        text={'Trova la soluzione migliore per uno degli interventi più importanti della casa: la ristrutturazione bagno. Con un progetto chiavi in mano che vi accompagna dall’ideazione alla realizzazione della ristrutturazione bagno, ogni cliente potrà contare su un servizio efficiente e di alta qualità.'}
                        ctaText='SCOPRI HOUSE BAGNI'
                        ctaLink='bagni'
                    />
                </div>
                <div className="inner-page">
                    <ImageAndText 
                        img={'https://images.unsplash.com/photo-1587582423116-ec07293f0395?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'}
                        imgAlt='alt'
                        eyebrow='HOUSE TETTI'
                        title='PROTEGGI CHI TI PROTEGGE'
                        text={'Affidati alla nostra esperienza pluriennale nel settore del rifacimento tetti. Grazie a noi risolverai tutti i tuoi problemi senza ponteggi e complicazioni. Perché chiamare i professionisti conviene!'}
                        ctaText='SCOPRI HOUSE TETTI'
                        ctaLink='tetti'
                        invert
                    />
                </div>
                <div className="inner-page black">
                    <ValuePropositionTable 
                        title={'Il nostro metodo di lavoro'}
                        text="Le fasi di avanzamento lavori, trasparenti e pensate sul cliente."
                        icons={[
                            {
                                title: 'Fase 1', 
                                text: "Contattaci inviando i dettagli dell'intervento", 
                                icon: 'contract_edit'
                            },
                            {
                                title: 'Fase 2', 
                                text: 'Un nostro commerciale vi contatterà per un sopralluogo gratuito', 
                                icon: 'design_services'
                            },
                            {
                                title: 'Fase 3', 
                                text: 'Messa in opera del cantiere ed inizio dei lavori', 
                                icon: 'engineering'
                            },
                            {
                                title: 'Fase 4', 
                                text: 'Fine dei lavori e cliente soddisfatto', 
                                icon: 'heart_check'
                            },
                        ]}
                    />
                </div>
                <DoubleImage 
                    img={'https://images.unsplash.com/photo-1650627404628-5d0eca0d9f19?q=80&h=2000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'}
                    img2={'https://images.unsplash.com/photo-1512428559087-560fa5ceab42?q=80&h=2000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'}
                    imgAlt='alt'
                    imgAlt2='alt'
                    title='Installazione e Manutenzione'
                    title2='Servizio clienti sempre a disposizione'
                />
                <DoubleImage 
                    img={'https://images.unsplash.com/photo-1599619585749-a6cfd38d358a?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'}
                    img2={'https://images.unsplash.com/photo-1518976024611-28bf4b48222e?q=80&w=2785&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'}
                    imgAlt='alt'
                    imgAlt2='alt'
                    title='Utilizzo di materiali certificati'
                    title2='Preventivi su misura'
                />
                <DoubleImage 
                    img={'https://images.unsplash.com/photo-1681505504714-4ded1bc247e7?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'}
                    imgAlt='alt'
                    title='Ti abbiamo incuriosito?'
                    ctaText='SCOPRI COME LAVORIAMO'
                    ctaLink='#about-us'
                />
                <div id="contact" className="inner-page black contact">
                    <ContactForm />
                </div>
                <Footer/>
            </div>
        </div>
    )
}

export default Home