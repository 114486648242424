import React from "react"
import classNames from "classnames"
import Icon from "./Icon";
const c = classNames;


const ImageAndText = (props) => {    
    return (
        <div className={c('hero-component')}>
            {props.img && (
                <div className="img-wrapper">
                    <img className="img-cover" src={props.img} alt={props.imgAlt || ''} />
                    {props.title && (
                        <div className="text-wrapper">
                            {props.logo && (
                                <img className="hero-logo" src={props.logo} alt="Logo House Bagni" />
                            )}
                            <p className="reg-font" data-aos="fade-up">{props.title}</p>
                            {props.ctaLink && props.ctaText && (
                                <div className="cta anchor" data-aos="fade-up">
                                    <a href={props.ctaLink}>{props.ctaText}</a>
                                    {props.ctaLink.includes('#') ? (
                                        <Icon name={'arrow_downward'}></Icon>
                                    ) : (
                                        <Icon name={'arrow_forward'}></Icon>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default ImageAndText