import React, { useRef, useState } from "react"
import Icon from "./Icon";
import { Form, Field } from 'react-final-form'
import HouseBagniLogo from '../assets/logo/logo-bagni-white.svg'
import HouseTettiLogo from '../assets/logo/logo-tetti-white.svg'


const ContactForm = (props) => {
    
    const [brand, setBrand] = useState(props.brand);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [submittedValues, setSubmittedValues] = useState({});
    const formRef = useRef(null);
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

    const onSubmit = async values => {
        await sleep(300)

        console.log(values);

        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: new URLSearchParams(values).toString()
          })
            .then(() => {
                setIsSubmitted(true);
                setSubmittedValues(values)
            })
            .catch(error => {
                console.alert(error)
            });  
    }

    if (!brand) {
        return (
            <div className="contact-form-component general">
                <p className="form-title">VORREI UN PREVENTIVO PER:</p>
                <div className="brand-choose">
                    <button onClick={() => setBrand('bagni')}>
                        <img src={HouseBagniLogo} alt="" />
                    </button>
                    <button onClick={() => setBrand('tetti')}>
                        <img src={HouseTettiLogo} alt="" />
                    </button>
                </div>
            </div>
        )
    }


    return (
        <div className="contact-form-component">
            <div className="form-wrapper">
                <p className="form-title">Richiedi il tuo preventivo</p>
                {isSubmitted ? (
                    <>
                    <p className="feedback-message">Ciao <span>{submittedValues.firstName}</span>, la tua richiesta è stata inoltrata, riceverai presto un contatto al tuo numero di telefono <span>{submittedValues.phone}</span> e alla mail <span>{submittedValues.email}</span> inserita precedentemente. <br/>Grazie mille per averci scelto!</p>
                    </>
                ) : (
                <Form
                    onSubmit={onSubmit}
                    validate={values => {
                        const errors = {}

                        const requiredValues = ['firstName', 'email', 'phone', 'province'];

                        var regExpEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                        var regExpPhone = /^(\+\d{1,2}\s?)?1?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

                        requiredValues.forEach((v) => {
                            if (!values[v]) {
                                errors[v] = 'Campo obbligatorio'
                              }
                        })

                        if (values.email && !regExpEmail.test(values.email)) {
                            errors.email = 'Email non valida'
                        }

                        if (values.phone && !regExpPhone.test(values.phone)) {
                            errors.phone = 'Telefono non valido'
                        }

                        return errors
                      }}
                    initialValues={{ brand: brand}}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form ref={formRef} className="contact-form" name="contact" data-netlify="true" onSubmit={handleSubmit}>

                            <input type="hidden" name="form-name" value="contact" />

                            <Field name="firstName">
                                {({ input, meta }) => (
                                    <div className="form-input">
                                    <label>Nome e Cognome</label>
                                    <input {...input} type="text" name="name"/>
                                    {meta.error && meta.touched && <span className="error">{meta.error}</span>}
                                    </div>
                                )}
                            </Field>
                            <Field name="email">
                                {({ input, meta }) => (
                                    <div className="form-input">
                                    <label>Email</label>
                                    <input {...input} type="text" name="email" />
                                    {meta.error && meta.touched && <span className="error">{meta.error}</span>}
                                    </div>
                                )}
                            </Field>
                            <Field name="phone">
                                {({ input, meta }) => (
                                    <div className="form-input">
                                    <label>Telefono</label>
                                    <input {...input} type="text" name="phone" />
                                    {meta.error && meta.touched && <span className="error">{meta.error}</span>}
                                    </div>
                                )}
                            </Field>
                            <Field name="province">
                                {({ input, meta }) => (
                                    <div className="form-input">
                                    <label>Provincia</label>
                                    <select {...input} name="province">
                                        <option value=''>-</option>
                                        <option value="VR">Verona</option>
                                        <option value="TR">Trento</option>
                                        <option value="VC">Vicenza</option>
                                        <option value="PD">Padova</option>
                                        <option value="BS">Brescia</option>
                                        <option value="MV">Mantova</option>
                                        <option value="CR">Cremona</option>
                                        <option value="BG">Bergamo</option>
                                        <option value="MI">Milano</option>
                                        <option value="MB">Monza</option>
                                        <option value="LC">Lecco</option>
                                        <option value="LO">Lodi</option>
                                    </select>
                                    {meta.error && meta.touched && <span className="error">{meta.error}</span>}
                                    </div>
                                )}
                            </Field>
                            <Field name="note">
                                {({ input, meta }) => (
                                    <div className="form-input">
                                    <label>Note</label>
                                    <textarea {...input} type="text-area" placeholder="Scrivi in breve di cosa hai bisogno" name="note" />
                                    {meta.error && meta.touched && <span className="error">{meta.error}</span>}
                                    </div>
                                )}
                            </Field>
                            <Field name="brand">
                                {({ input, meta }) => (
                                    <input {...input} type="hidden" value={brand} name="brand"/>
                                )}
                            </Field>
                        <div className="buttons">
                        {/* {!props.brand && (
                            <div className="cta secondary">
                                <button type="button" onClick={() => setBrand(null)}>
                                <Icon name={'arrow_back'}></Icon>
                                </button>
                            </div>
                        )} */}
                        {/* <div className="cta">
                                <button type="button" onClick={form.reset} disabled={submitting || pristine}>
                                Reset
                                </button>
                                <Icon name={'close'}></Icon>
                            </div> */}
                            <div className="cta">
                                <button type="submit" disabled={submitting || pristine}>
                                Invia
                                </button>
                                <Icon name={'arrow_forward'}></Icon>
                            </div>
                        </div>
                        </form>
                    )}
                />
                )}
                
            </div>
            <div className="contact-wrapper">
                <p className="form-title">Oppure contattaci</p>
                {brand === 'tetti' && (
                    <div className="contacts-wrapper">
                    <p className="contact-line"><Icon name='phone'/> <a href="tel:+39 3355706734">+39 335 570 6734</a></p>
                    <p className="contact-line"><Icon name='mail'/> <a href="mailto:housetetti@gmail.com">housetetti@gmail.com</a></p>
                    <p className="contact-line"><Icon name='person'/> <a target='_blank' rel="noreferrer" href="https://www.instagram.com/housetetti/">@housetetti</a></p>
                    </div>
                )}
                {brand === 'bagni' && (
                    <div className="contacts-wrapper">
                    <p className="contact-line"><Icon name='phone'/> <a href="tel:+39 3355706734">+39 335 570 6734</a></p>
                    <p className="contact-line"><Icon name='mail'/> <a href="mailto:housebagni@gmail.com">housebagni@gmail.com</a></p>
                    <p className="contact-line"><Icon name='person'/> <a target='_blank' rel="noreferrer" href="https://www.instagram.com/housebagni/">@housebagni</a></p>
                    </div>
                )}
            </div>
        </div>
    )
}

export default ContactForm