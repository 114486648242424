import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import {
  createBrowserRouter,
  RouterProvider,
  // Link,
} from "react-router-dom";
import './styles/main.scss';

import Home from './pages/Home';
import Bagni from './pages/Bagni';
import Tetti from './pages/Tetti';
// import About from './pages/About';

import Header from './components/Header';


const nav = [
  {
    path: "/",
    element: <Home/>,
    name: 'Home',
    isInHeader: false
  },
  // {
  //   path: "about-us",
  //   element: <About/>,
  //   name: 'Chi siamo',
  //   isInHeader: true,
  //   icon: 'question_mark'
  // },
  {
    path: "bagni",
    element: <Bagni/>,
    name: 'Bagni',
    isInHeader: true,
    icon: 'shower'
  },
  {
    path: "tetti",
    element: <Tetti/>,
    name: 'Tetti',
    isInHeader: true,
    icon: 'gite'
  }
]

const router = createBrowserRouter(nav);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <Header nav={nav}/>
      <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
