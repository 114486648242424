import React, { useEffect, useState } from "react"
import Icon from "./Icon";
import classNames from "classnames"
const c = classNames;
// import {Link} from "react-router-dom";


const Header = (props) => {    
    const [menuOpen, setMenuOpen] = useState(false);
    const [scrollDirection, setScrollDirection] = useState('');
    const contactFormExist = true;

    useEffect(() => {
        let lastScrollY = window.scrollY;
        const handleScroll = () => {
          let direction = lastScrollY < window.scrollY ? 'down' : 'up';
          lastScrollY = window.scrollY;
          setScrollDirection(direction);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        if (menuOpen && scrollDirection !== '') {
            setMenuOpen(false);
        }
       
    }, [scrollDirection, menuOpen]);

    return (
        <>
            <header className={c('header', {hide: scrollDirection === 'down' && !menuOpen})}>
                <h1 className={c('logo', 'notranslate')}><a href="/">HOUSE<span>EDILIZIA</span></a></h1>
                <ul className={c('nav-list')}>
                    {props.nav.filter(el => el.isInHeader).map((navEl, i) => (
                        <li className="nav-item" key={i}><a href={'/' + navEl.path}>{navEl.name}</a></li>
                        ))}
                    {contactFormExist && (
                        <li><a className="nav-item evidence" href={'#contact'}>Richiedi preventivo <Icon name='chat'/></a></li>
                    )}
                </ul>
                <button 
                    onClick={() => {setMenuOpen(!menuOpen); setScrollDirection('')}} 
                    className={classNames('hamburger-button', {open: menuOpen})}><Icon name={menuOpen ? 'close' : 'menu'}></Icon></button>
            </header>
            <nav className={c('mobile-menu', {hidden: !menuOpen})}>
                <ul className={c('nav-list')}>
                    <li className="nav-item"><Icon name={'home'}></Icon><a href={'/'} onClick={() => setMenuOpen(false)}>Home</a></li>
                    {props.nav.filter(el => el.isInHeader).map((navEl, i) => (
                        <li className="nav-item" key={i}><Icon name={navEl.icon}></Icon><a href={'/' + navEl.path}>{navEl.name}</a></li>
                        ))}
                    {contactFormExist && (
                        <li className="nav-item"><Icon name={'chat'}></Icon><a href={'#contact'} onClick={() => setMenuOpen(false)}>Richiedi preventivo</a></li>
                    )}
                </ul>
            </nav>
        </>
    )
}

export default Header