import React from "react"
import classNames from "classnames"
import Icon from "./Icon";
const c = classNames;


const ImageAndText = (props) => {    
    return (
        <div className={c('double-image-component', {invert: props.invert})}>
            {props.img && (
                <div className="img-wrapper">
                    <img src={props.img} alt={props.imgAlt || ''} />
                    {props.title && (
                        <div className="text-wrapper">
                            {props.logo1 && (
                                <img className="hero-logo" data-aos="fade-up" src={props.logo1} alt="Logo House Bagni" />
                            )}
                            <p className="reg-font" data-aos="fade-up">{props.title}</p>
                            {props.ctaLink && props.ctaText && (
                                <div className="cta" data-aos="fade-up">
                                    <a href={props.ctaLink}>{props.ctaText}</a>
                                    {props.ctaLink.includes('#') ? (
                                        <Icon name={'arrow_downward'}></Icon>
                                    ) : (
                                        <Icon name={'arrow_forward'}></Icon>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}
            {props.img2 && (
                <div className="img-wrapper">
                    <img src={props.img2} alt={props.imgAlt2 || ''} />
                    {props.title2 && (
                        <div className="text-wrapper">
                            {props.logo2 && (
                                <img className="hero-logo" data-aos="fade-up" src={props.logo2} alt="Logo House Bagni" />
                            )}
                            <p className="reg-font" data-aos="fade-up">{props.title2}</p>
                            {props.ctaLink2 && props.ctaText2 && (
                                <div className="cta" data-aos="fade-up">
                                    <a href={props.ctaLink2}>{props.ctaText2}</a>
                                    <Icon name={'arrow_forward'}></Icon>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default ImageAndText