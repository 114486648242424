import React from "react"
// import Icon from "./Icon";
import classNames from "classnames"
const c = classNames;
// import {Link} from "react-router-dom";

const Footer = (props) => {    
    return (
        <footer className={c('footer', 'notranslate')}>
            <h1 className={c('logo')}><a href="/">HOUSE<span>EDILIZIA</span></a></h1>
            <div className="info-grid">
                <div className="info-box">
                    <p className="title-box">Contatti House Bagni</p>
                    <p>Telefono: <a href="tel:+39 3355706734">+39 335 570 6734</a></p>
                    <p>Mail: <a href="mailto:housebagni@gmail.com">housebagni@gmail.com</a></p>
                </div>
                <div className="info-box">
                    <p className="title-box">Social House Bagni</p>
                    <p><a target='_blank' rel="noreferrer" href="https://www.instagram.com/housebagni/">Instagram</a></p>
                    <p><a target='_blank' rel="noreferrer" href="https://www.facebook.com/profile.php?id=61568055878087">Facebook</a></p>
                </div>
                <div className="info-box">
                    <p className="title-box">Dati Societari House Bagni</p>
                    <p>Partita IVA: 04521340986</p>
                </div>
                <div className="info-box">
                    <p className="title-box">Contatti House Tetti</p>
                    <p>Telefono: <a href="tel:+39 3355706734">+39 335 570 6734</a></p>
                    <p>Mail: <a href="mailto:housetetti@gmail.com">housetetti@gmail.com</a></p>
                </div>
                <div className="info-box">
                    <p className="title-box">Social House Tetti</p>
                    <p><a target='_blank' rel="noreferrer" href="https://www.instagram.com/housetetti/">Instagram</a></p>
                    <p><a target='_blank' rel="noreferrer" href="https://www.facebook.com/profile.php?id=61568378609388">Facebook</a></p>
                </div>
                <div className="info-box">
                    <p className="title-box">Dati Societari House Tetti</p>
                    <p>Partita IVA: 04625620986</p>
                </div>
            </div>
        </footer>
    )
}

export default Footer