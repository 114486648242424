import React from "react"
import classNames from "classnames"
import Icon from "./Icon";
const c = classNames;


const ImageAndText = (props) => {    
    return (
        <div className={c('image-and-text-component', {invert: props.invert})}>
            {props.img && <img src={props.img} alt={props.imgAlt || ''} />}
            <div className="text-wrapper">
                <span className="eyebrow">{props.eyebrow}</span>
                <h3 className="reg-font" data-aos="fade-up">{props.title}</h3>
                <p data-aos="fade-up">{props.text}</p>
                {props.ctaLink && (
                    <div className="cta" data-aos="fade-up">
                        <a href={'/' + props.ctaLink}>{props.ctaText}</a>
                        <Icon name={'arrow_forward'}></Icon>
                    </div>
                )}
            </div>
        </div>
    )
}

export default ImageAndText