import React from "react"
import classNames from "classnames"
import Icon from "./Icon";
const c = classNames;


const ToDoList = (props) => {    
    return (
        <div className={c('to-do-list-component')}>
            <h3>{props.title}</h3>
            <ul className="list-wrapper">
                {props.activities.map((a, i) => (
                    <li><Icon name={'check_box'} /> <span>{a}</span></li>
                ))}
            </ul>
        </div>
    )
}

export default ToDoList