import React, {useEffect} from "react"
import AOS from "aos";
import "aos/dist/aos.css";
import Footer from "../components/Footer"
import ImageAndText from "../components/ImageAndText"
import Hero from "../components/Hero"
import ValuePropositionTable from '../components/ValuePropositionTable'
import ContactForm from "../components/ContactForm";
import HouseTettiLogo from '../assets/logo/logo-tetti-white.svg'
import ToDoList from "../components/TodoList";

const Tetti = (props) => {

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <div className="page tetti">
            <div className="container">
                <Hero 
                    img={'https://images.unsplash.com/photo-1489514354504-1653aa90e34e?q=80&w=2942&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'}
                    imgAlt='alt'
                    title='PROTEGGI CHI TI PROTEGGE'
                    logo={HouseTettiLogo}
                />
                <div className="inner-page">
                    <ImageAndText 
                        img={'https://res.cloudinary.com/dtfhceefz/image/upload/v1733828274/Instagram_post_-_7a_jqlzwb.jpg'}
                        imgAlt='alt'
                        eyebrow='Ripara il tuo tetto con risultati duraturi'
                        title='Valuta la tua offerta a partire da 6000€'
                        text={'Affidati alla nostra esperienza pluriennale nel settore del rifacimento tetti. Grazie a noi risolverai tutti i tuoi problemi senza ponteggi e complicazioni. Perché chiamare i professionisti conviene! '}
                        // ctaText='SCOPRI HOUSE BAGNI'
                        // ctaLink='about-us'
                    />
                </div>
                <div className="inner-page">
                    <ToDoList title="I nostri servizi" 
                        activities={[
                            "Fornitura e posa linee vita provvisorie. Le linee vita provvisorie verranno rimosse a fine lavoro.",
                            "I lavori verranno eseguiti da personale qualificato per i lavori in quota.",
                            "Secondo le normative vigenti verranno utilizzati sistemi di anticaduta idonei e a norma.",
                            "Redazione piano operativo di sicurezza e assicurazione di cantiere.",
                            "Ripasso professionale del manto di copertura esistente.",
                            "Sostituzione dei coppi o tegole rotti o danneggiati.",
                            "Fissaggio con schiuma apposita certificata di tutti gli elementi coppi o tegole.",
                            "Pulizia grondaie e controllo canali di scolo e giunti.",
                            "Sigillatura comignoli e camini se esistenti.",
                            "Sigillatura lucernari se presenti.",
                            "Sigillatura grondaie e giunti.",
                            "Fornitura e posa di assito OSB.",
                            "Fornitura e posa guaina ardesiata/onduline.",
                            "Fornitura e posa nuovi coppi/tegole.",
                            "Trattamento idrorepellente.",
                            "Smaltimento materiale di risulta."
                        ]} 
                    />
                </div>
                <div className="inner-page">
                    <ImageAndText
                        invert
                        img={'https://res.cloudinary.com/dtfhceefz/image/upload/v1733827963/Instagram_post_-_14_stkv6t.jpg'}
                        imgAlt='alt'
                        eyebrow='Come lavoriamo?'
                        title='Manutenzione Completa del Tetto con HouseTetti: Sicurezza, Efficienza e Durabilità'
                        text={"L'intervento di manutenzione HouseTetti garantisce la sicurezza e l'efficienza del tuo tetto attraverso una serie di operazioni mirate. Iniziamo con l'installazione di una linea vita provvisoria e la redazione di un piano di sicurezza. Procediamo con un ripasso professionale del manto di copertura, la sostituzione di tegole danneggiate e il fissaggio con schiuma certificata per una tenuta ottimale. Ci occupiamo anche della pulizia delle grondaie, della sigillatura di camini e lucernari, e dello smaltimento dei materiali in modo ecologico. Con HouseTetti, il tuo tetto sarà sicuro, duraturo e perfettamente funzionale."}

                    />
                </div>
                <div className="inner-page">
                    <ImageAndText 
                        img={'https://us.123rf.com/450wm/jilapong/jilapong2311/jilapong231144568/217866488-ingegneri-designer-e-interior-designer-si-stringono-la-mano-si-occupano-di-finalizzare-la.jpg?ver=6'}
                        imgAlt='alt'
                        eyebrow='Intervieni in tempo'
                        title='Risparmia fino ad un 70% rispetto al rifacimento dell’intera copertura'
                        text={'Hai infiltrazioni il tuo tetto ti sembra malconcio, coppi o tegole scivolati, grondaie staccate o con crepi... ti hanno detto che devi rifare il manto di copertura. Stai quindi pensando di rinunciare alle ferie estive quest’anno? Stai abbandonando l’idea di cambiare l’auto? Prenota le tue vacanze e compra pure l’auto dei tuoi sogni.'}
                        invert
                    />
                </div>
                <div className="inner-page black">
                    <ValuePropositionTable 
                        title={'Il nostro sistema ti offre'}
                        text=""
                        icons={[
                            {
                                title: 'Risparmio del 70% rispetto ai sistemi tradizionali', 
                                // text: "Ispezione con drone, verifica copertura esistente, verifica grondaie, lucernari e camini", 
                                icon: 'payments'
                            },
                            {
                                title: 'Intervento in sicurezza senza utilizzo di ponteggi', 
                                // text: 'Preventivi accurati e gratuiti', 
                                icon: 'person_apron'
                            },
                            {
                                title: 'Tempi brevi', 
                                // text: 'Intervento in edilizia acrobatica utilizzando materiali certificati e garanzia completa sul lavoro svolto', 
                                icon: 'timer'
                            },
                            {
                                title: 'Lavoro garantito', 
                                // text: 'Disponibilità massima e serietà', 
                                icon: 'badge'
                            },
                        ]}
                    />
                </div>
                <div className="inner-page">
                    <ImageAndText 
                        img={'https://res.cloudinary.com/dtfhceefz/image/upload/v1733826777/Instagram_post_-_5_wn2gje.jpg'}
                        imgAlt='alt'
                        eyebrow=''
                        title='Lavoriamo in Lombardia, Trentino e Veneto'
                        text={'La nostra azienda edilizia, con anni di esperienza nel settore, è specializzata nella realizzazione e manutenzione di tetti in Lombardia, Veneto e Trentino. Offriamo soluzioni su misura per ogni tipo di esigenza, garantendo la massima qualità dei materiali e la cura dei dettagli in ogni fase del lavoro. Grazie a un team altamente qualificato e all’utilizzo delle tecnologie più innovative, siamo in grado di realizzare tetti sicuri, esteticamente eleganti e durevoli nel tempo.'}
                        invert
                    />
                </div>
                <div className="inner-page black">
                    <ValuePropositionTable 
                        title={'I nostri servizi'}
                        text=""
                        icons={[
                            {
                                title: 'Ispezione del vostro tetto con drone', 
                                text: "Ispezione con drone, verifica copertura esistente, verifica grondaie, lucernari e camini", 
                                icon: 'search'
                            },
                            {
                                title: 'Preventivi su misura', 
                                text: 'Preventivi accurati e gratuiti', 
                                icon: 'contract_edit'
                            },
                            {
                                title: 'Installazione e Manutenzione', 
                                text: 'Intervento in edilizia acrobatica utilizzando materiali certificati e garanzia completa sul lavoro svolto', 
                                icon: 'engineering'
                            },
                            {
                                title: 'Servizio clienti sempre a disposizione', 
                                text: 'Disponibilità massima e serietà', 
                                icon: 'support_agent'
                            },
                        ]}
                    />
                </div>
                <Hero 
                    img={'https://images.unsplash.com/photo-1541888946425-d81bb19240f5?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'}
                    imgAlt='alt'
                    title='Vuoi un nostro intervento?'
                    ctaText='Richiedi un preventivo'
                    ctaLink='#contact'
                />
                <div id="contact" className="inner-page black contact">
                    <ContactForm brand='tetti' />
                </div>
                <Footer/>
            </div>
        </div>
    )
}

export default Tetti