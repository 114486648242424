import React from "react"
import classNames from "classnames"
import Icon from "./Icon";
const c = classNames;


const ValuePropositionTable = (props) => {    
    return (
        <div className={c('value-table-component')}>
            <div className={c("text-box", {small: props.icons.length < 5})} data-aos="fade-up">
                <h3 className="">{props.title}</h3>
                {props.text && <p>{props.text}</p>}
            </div>
            {props.icons.map((i) => (
                <div className="value-item">
                    <Icon name={i.icon}></Icon>
                    <p className="value-title">{i.title}</p>
                    <p className="value-text">{i.text}</p>
                </div>
            ))}
            
            
        </div>
    )
}

export default ValuePropositionTable