import React from "react"
import classNames from "classnames"
// import ColumnsPhotoAlbum from "react-photo-gallery";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
const c = classNames;


const photos = [
    {
      src: "https://images.unsplash.com/photo-1571781418606-70265b9cce90?q=80&w=2787&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      width: 4,
      height: 3
    },
    {
      src: "https://images.unsplash.com/photo-1521783593447-5702b9bfd267?q=80&w=3004&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      width: 1,
      height: 1
    },
    {
      src: "https://images.unsplash.com/photo-1552321554-5fefe8c9ef14?q=80&w=2787&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      width: 3,
      height: 4
    },
    {
      src: "https://images.unsplash.com/photo-1564540579594-0930edb6de43?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      width: 4,
      height: 3
    },
    {
      src: "https://images.unsplash.com/photo-1531125227120-bac862d2aeb9?q=80&w=2835&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      width: 3,
      height: 4
    },
    {
      src: "https://images.unsplash.com/photo-1564540583246-934409427776?q=80&w=2906&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      width: 4,
      height: 3
    },
    {
      src: "https://images.unsplash.com/photo-1533093818119-ac1fa47a6d59?q=80&w=2787&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      width: 3,
      height: 4
    },
    {
      src: "https://images.unsplash.com/photo-1523772354886-34a1dc2f72e7?q=80&w=1536&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      width: 4,
      height: 3
    },
    {
      src: "https://images.unsplash.com/photo-1594873672629-61079318a5fd?q=80&w=2787&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      width: 4,
      height: 3
    },
    {
      src: "https://images.unsplash.com/photo-1573385044784-ba6c83bfa408?q=80&w=2815&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      width: 3,
      height: 4
    },
    {
      src: "https://plus.unsplash.com/premium_photo-1676968003305-307c441dbbd3?q=80&w=2787&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      width: 4,
      height: 3
    },
    {
      src: "https://images.unsplash.com/photo-1552454799-ca5cfdc612c8?q=80&w=2874&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      width: 3,
      height: 4
    },
  ];  

const ImagesWall = (props) => {    
    return (
        <div className={c('images-wall-component')}>
            <h3>{props.title}</h3>
            <ResponsiveMasonry columnsCountBreakPoints={{350: 2, 750: 3}}>
                <Masonry gutter={8}>
                    {photos.map((p, i) => (
                        <img key={i} onClick={() => console.log('open image', i)} src={p.src} alt="" />
                    ))}
                </Masonry>
            </ResponsiveMasonry>
        </div>
    )
}

export default ImagesWall